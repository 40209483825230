  import { useRef } from 'react';
  import emailjs from '@emailjs/browser';
import "../styles/home.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';

import img1 from "../assets/images/1.jpg"
import img2 from "../assets/images/7.jpeg"
import img3 from "../assets/images/6.jpg"
import img4 from "../assets/images/4.jpg"

import truck from "../assets/images/truck.png"
import world from "../assets/images/world.png"

import aboutimg from "../assets/images/about1.jpg"

import road from "../assets/images/road.png"

import workingimg1 from "../assets/images/working1.png"
import workingimg2 from "../assets/images/working2.png"
import workingimg3 from "../assets/images/working3.png"


import gallery1 from "../assets/images/truckbanner1.jpeg"
import gallery2 from "../assets/images/truckbanner2.jpeg"
import gallery3 from "../assets/images/truckbanner3.jpeg"
import gallery4 from "../assets/images/truckbanner.jpeg"
import gallery5 from "../assets/images/truckbanner4.jpeg"
import gallery6 from "../assets/images/truckbanner6.png"

import manimg from "../assets/images/man.png"

function Arrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none" }}
      onClick={onClick}
    />
  );
}

const Home = () => {

  const form = useRef()

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_u75qu0i', 'template_77m8utb', form.current, {
        publicKey: 'oXzzL9lxOoTsLe3uM',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );

    e.target.reset();
  };


  const settings = {
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    nextArrow: <Arrow />,
    prevArrow: <Arrow />,
  };

  const service = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  };

  return (
    <>

      <Slider {...settings}>
        <div className="slider slider-1">
          <div className="slider-text">
            <h1 className="animate__animated animate__fadeInUp">Streamline Your Transportation Management Effortlessly with MyLoader</h1>

            <div className='space'>
              <button className="btn1"><Link to="/contact">Enquiry</Link><i class="fa-regular fa-paper-plane icon_plane" style={{ paddingLeft: "10px" }}></i></button>
              <button style={{ marginLeft: "20px" }} className="btn2"><Link to="/service">our Services</Link><i class="fa-regular fa-paper-plane icon_plane" style={{ paddingLeft: "10px" }}></i></button>
            </div>
          </div>
        </div>

        <div className="slider slider-3">
          <div className="slider-text">
            <h1 className="animate__animated animate__fadeInUp">Unlock the potential of your transportation network with Myloader</h1>
            <div className='space'>
              <button className="btn1"><Link to="/contact">Enquiry</Link><i class="fa-regular fa-paper-plane icon_plane" style={{ paddingLeft: "10px" }}></i></button>
              <button style={{ marginLeft: "20px" }} className="btn2"><Link to="/service">our Services</Link><i class="fa-regular fa-paper-plane icon_plane" style={{ paddingLeft: "10px" }}></i></button>
            </div>
          </div>
        </div>

        <div className="slider slider-4">
          <div className="slider-text">
            <h1 className="animate__animated animate__fadeInUp">Efficient transportation management begins with Myloader</h1>
            <div className='space'>
              <button className="btn1"><Link to="/contact">Enquiry</Link><i class="fa-regular fa-paper-plane icon_plane" style={{ paddingLeft: "10px" }}></i></button>
              <button style={{ marginLeft: "20px" }} className="btn2"><Link to="/service">our Services</Link><i class="fa-regular fa-paper-plane icon_plane" style={{ paddingLeft: "10px" }}></i></button>
            </div>
          </div>
        </div>
      </Slider>


      <section className="home_section">
        <div className="container">
          <div className="row">
            <div className="col col-sm-3" style={{ marginTop: "40px" }}>
              <div className="features" style={{ marginBottom: "30px" }}>
                <div className="features_img" style={{ position: "relative" }}>
                  <img src={img1} alt="" className="home_img" />
                  <div className="feature_text">
                    <div className="feature_icon">
                      <img src={truck} alt="" className='home_icon' />
                    </div>
                    <h3>We Provide Tempo <br /> Freight In City</h3>

                  </div>
                  <div className="feature_btn1">
                    <Link to="/service">Read More</Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col col-sm-3" style={{ marginTop: "40px" }}>
              <div className="features" style={{ marginBottom: "30px" }}>
                <div className="features_img" style={{ position: "relative" }}>
                  <img src={img2} alt="" height={362} className='home_img' />
                  <div className="feature_text">
                    <div className="feature_icon">
                      <img src={truck} alt="" className='home_icon' />
                    </div>
                    <h3>We Provide Single Axle Truck <br /> Freight In City</h3>

                  </div>
                  <div className="feature_btn1">
                    <Link to="/service">Read More</Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col col-sm-3" style={{ marginTop: "40px" }}>
              <div className="features" style={{ marginBottom: "30px" }}>
                <div className="features_img" style={{ position: "relative" }}>
                  <img src={img3} alt="" height={362} className='home_img' />
                  <div className="feature_text">
                    <div className="feature_icon">
                      <img src={truck} alt="" className='home_icon' />
                    </div>
                    <h3>We Provide Mini Truck <br /> Freight In City</h3>

                  </div>
                  <div className="feature_btn1">
                    <Link to="/service">Read More</Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col col-sm-3" style={{ marginTop: "40px" }}>
              <div className="features" style={{ marginBottom: "30px" }}>
                <div className="features_img" style={{ position: "relative" }}>
                  <img src={img4} alt="" className='home_img' />
                  <div className="feature_text">
                    <div className="feature_icon">
                      <img src={world} alt="" className='home_icon' />
                    </div>
                    <h3>We Provide Much <br /> More Services</h3>

                  </div>
                  <div className="feature_btn1">
                    <Link to="/service">Read More</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      {/*  about us start  */}

      <div className="about_us">
        <div className="container">
          <div className="row">
            <div className="col col-sm-6">
              <div className="about_us_img">
                <img src={aboutimg} alt="" className="aboutimg" />
              </div>
            </div>

            <div className="col col-sm-6 about_space">
              <div className="about_us_content" style={{ marginBottom: "30px" }}>
                <div className="about_us_title" style={{ marginBottom: "35px" }}>
                  <span className="about_main_title" style={{ paddingTop: "40px" }}>About Us</span>
                  <h2>Modern &  Trusted MyLoader Transport Company</h2>
                </div>
                <div className="about_us_para">
                  <span>MD of MLTC
                    MR ABHAY JASANI
                    ABHAY SIR IS IN THIS TRANSPORT BUSINESS SINCE LAST 24 YEARS AS A SILENT INVESTOR... NOW MR ABHAY IS READY TO START TRANSPORT SERVICE COMPANY IN MP INDORE, AND MY LOADER TRANCPORT COMPANY IS COMING UP WITH THREE BRANCHES IN MP.</span>
                  <div className="about_button">
                    <Link to="/about" className="about_btn">Learn More <i class="fa-regular fa-paper-plane" style={{ marginLeft: "10px" }}></i></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* about us end */}


      {/*  Service section start  */}

      <div className="service_area">
        <div className="container">
          <div className="row">
            <div className="col col-sm-12">
              <div className="service_title" style={{ marginRight: "60px", marginLeft: "60px", marginBottom: "75px", textAlign: "center", position: "relative" }}>
                <span className="border_left_1"></span>
                <span>Our Services</span>
                <span className='border_right_1'></span>
                <h2>complete road transport solutions under one <br /> roof you name it we do it</h2>
              </div>
            </div>
          </div>

          <div className="row">
            <div className='col-sm-12'>
              <Slider {...service}>
                <div className="sitem">
                  <div className="service_section">
                    <div className="service_icon">
                      <img src={road} alt="" />
                    </div>
                    <div className="service_text">
                      <h3>Mini Truck Road Freight</h3>
                      <p>Providing efficient and reliable transportation with our fleet of mini trucks. Your cargo's journey begins here, ensuring timely and secure deliveries every time.</p>
                      <div className="service_btn">
                        <Link to="/service">Read More</Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="sitem">
                  <div className="service_section">
                    <div className="service_icon">
                      <img src={road} alt="" />
                    </div>
                    <div className="service_text">
                      <h3>407 Truck Road Freight</h3>
                      <p>Your go-to for dependable transportation. Count on our 407 trucks and dedicated team for swift and secure cargo delivery. Let's move your goods efficiently!</p>
                      <div className="service_btn">
                        <Link to="/service">Read More</Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" sitem">
                  <div className="service_section">
                    <div className="service_icon">
                      <img src={road} alt="" />
                    </div>
                    <div className="service_text">
                      <h3>709 Truck Road Freight</h3>
                      <p>Specializing in 709 truck services, we ensure prompt and secure transportation for your goods. Trust us to deliver excellence every mile of the way.</p>
                      <div className="service_btn">
                        <Link to="/service">Read More</Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="sitem">
                  <div className="service_section">
                    <div className="service_icon">
                      <img src={road} alt="" />
                    </div>
                    <div className="service_text">
                      <h3>Van Road Freight</h3>
                      <p>Your go-to solution for dependable van transportation. With our efficient vans and dedicated team, we ensure smooth delivery of your cargo. Let's move ahead together!</p>
                      <div className="service_btn">
                        <Link to="/service">Read More</Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="sitem">
                  <div className="service_section">
                    <div className="service_icon">
                      <img src={road} alt="" />
                    </div>
                    <div className="service_text">
                      <h3>Tempo Road Freight</h3>
                      <p>Your reliable partner for timely and efficient transportation needs. With our fleet of tempos and dedicated service, we ensure your cargo reaches its destination smoothly.</p>
                      <div className="service_btn">
                        <Link to="/service">Read More</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>

        </div>
      </div>

      {/* service section end  */}



      {/*  working process start  */}

      <div class="working-area" style={{ paddingBottom: "95px", paddingTop: "125px" }}>
        <div class="container">
          <div class="row">
            <div class="col col-sm-12">
              <div class="section-title section-2" style={{ textAlign: "center", position: "relative", marginRight: "60px", marginLeft: "60px", marginBottom: "75px" }}>
                <span class="border-left-1"></span>
                <span>Working Process</span>
                <span class="border-right-1"></span>
                <h2>We Follow Some Steps To <br /> Delivery Product</h2>
              </div>
            </div>

          </div>
          <div class="row">
            <div class="col col-sm-4">
              <div class="working-wrapper" style={{ textAlign: "center", marginBottom: "30px" }}>
                <div class="working-img">
                  <img src={workingimg1} alt="" />
                </div>
                <div class="working-text">
                  <h3>Truck Selection</h3>
                  <p>We specialize in helping you find the perfect truck for your needs, offering a wide range of options tailored to your requirements and budget.</p>
                </div>
              </div>
            </div>
            <div class="col col-sm-4 working1">
              <div class="working-wrapper" style={{ textAlign: "center", marginBottom: "30px" }}>
                <div class="working-img">
                  <img src={workingimg2} alt="" />
                </div>
                <div class="working-text">
                  <h3>Order Shipping</h3>
                  <p>We ensure swift and secure delivery of your orders, utilizing advanced logistics to guarantee timely arrivals. Trust us for efficient shipping services tailored to your needs.</p>
                </div>
              </div>
            </div>
            <div class="col col-sm-4">
              <div class="working-wrapper" style={{ textAlign: "center", marginBottom: "30px" }}>
                <div class="working-img">
                  <img src={workingimg3} alt="" />
                </div>
                <div class="working-text">
                  <h3>Express Delivery</h3>
                  <p>We specialize in lightning-fast delivery services, ensuring your packages reach their destination swiftly and securely. Trust us for unparalleled speed and reliability in shipping.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* working process end */}


      {/*  banner start  */}

      <div className="banner banner-1">
        <div className="banner-text">
          <h1>Reach Your Destination 100% Sure And Safe</h1>
          <button className="btn_banner"><Link to="/contact">Conatct Us</Link><i class="fa-regular fa-paper-plane" style={{ paddingLeft: "10px" }}></i></button>
        </div>
      </div>

      {/*  banner end  */}


      {/*  gallery start */}

      <div className="gallery">
        <div className="container">
          <div className="row">
            <div class="col col-sm-12">
              <div class="section-title section-2" style={{ textAlign: "center", position: "relative", marginRight: "60px", marginLeft: "60px", marginBottom: "75px" }}>
                <span class="border-left-1"></span>
                <span>Our Gallery</span>
                <span class="border-right-1"></span>
                <h2>Explore Our Latest <br /> projects</h2>
              </div>
            </div>
          </div>
        </div>


        <div className="container">
          <div className="row">
            <div className="col col-sm-4">
              <div className="gallery_img">
                <Link to="/gallery">
                  <img src={gallery1} alt="" />
                </Link>
              </div>
            </div>

            <div className="col col-sm-4">
              <div className="gallery_img">
                <Link to="/gallery">
                  <img src={gallery2} alt="" />
                </Link>
              </div>
            </div>

            <div className="col col-sm-4">
              <div className="gallery_img">
                <Link to="/gallery">
                  <img src={gallery3} alt="" />
                </Link>
              </div>
            </div>

            <div className="col col-sm-4">
              <div className="gallery_img">
                <Link to="/gallery">
                  <img src={gallery4} alt="" />
                </Link>
              </div>
            </div>

            <div className="col col-sm-4">
              <div className="gallery_img">
                <Link to="/gallery">
                  <img src={gallery5} alt="" />
                </Link>
              </div>
            </div>

            <div className="col col-sm-4">
              <div className="gallery_img">
                <Link to="/gallery">
                  <img src={gallery6} alt="" />
                </Link>
              </div>
            </div>

          </div>
        </div>
      </div>

      {/* gallery end */}


      {/* contact us start */}

      <div className="contact_section">
        <div className="contact_img" style={{ backgroundImage: "url(https://themeholy.com/wordpress/logistik/wp-content/uploads/2023/04/resquest_bg_1.png)" }}>
          <div className="container">
            <div className="contact_title" style={{ marginTop: "-0.25rem" }}>
              <h2 className="sec-title th-title">Contact Us</h2>
            </div>

            <form ref={form} onSubmit={sendEmail} className="contact_form">
              <input type="text" placeholder='Full Name' name='user_name' required style={{ marginTop: "15px", padding: "10px", outline: "none", border: "none", background: "white" }} />
              <input type="email" placeholder='Email' name='user_email' required style={{ marginTop: "15px", padding: "10px", outline: "none", border: "none", background: "white" }} />
              <input type="text" placeholder='weight' name='weight' required style={{ marginTop: "15px", padding: "10px", outline: "none", border: "none", background: "white" }} />
              <input type="text" placeholder='Distance' name='distance' required style={{ marginTop: "15px", padding: "10px", outline: "none", border: "none", background: "white" }} />
              <button type='submit' className='contact_btn' style={{ marginTop: "20px" }}>Send Message</button>
            </form>


            <div className="man">
              <div className="man_img">
                <img src={manimg} alt="" />
              </div>
            </div>



          </div>
        </div>
      </div>

      {/* contact us end  */}

    </>
  )
}

export default Home



