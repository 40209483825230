import React from 'react'
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
//import videobg from "../assets/images/videobg.mp4"



import gallery1 from "../assets/images/10.jpg"
import gallery2 from "../assets/images/11.jpg"
import gallery3 from "../assets/images/16.jpg"
import gallery4 from "../assets/images/13.jpg"
import gallery5 from "../assets/images/14.jpg"
import gallery6 from "../assets/images/15.jpg"
import gallery from "../assets/images/gallery.avif"
import gallery7 from "../assets/images/gallery2.avif"
import gallery8 from "../assets/images/gallery3.jpg"
import gallery9 from "../assets/images/gallery4.webp"
import gallery10 from "../assets/images/gallery5.jpg"
import gallery11 from "../assets/images/gallery6.jpg"

import { Link } from 'react-router-dom';

const Gallery = () => {

    return (
        <>

            <div class="breadcrumb-area" style={{ paddingBottom: "150px", paddingTop: "150px" }}>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="breadcrumb-text" style={{ textAlign: "center" }}>
                                <h1>Gallery</h1>
                                <ul class="breadcrumb-menu">
                                    <li><a href="/">Home</a></li>
                                    <li><span> <MdKeyboardDoubleArrowRight />
                                        Gallery</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="gallery">
                <div className="container">
                    <h1 style={{textAlign: "center", marginTop: "40px", color: "#ff5e14", fontSize: "40px", fontWeight: "600", fontFamily: "Poppins"}}>Our Gallery</h1>
                    <div className="row">
                        <div className="col col-sm-4">
                            <div className="gallery_img">
                                <Link to="/gallery">
                                    <img src={gallery1} alt="" />
                                </Link>
                            </div>
                        </div>

                        <div className="col col-sm-4">
                            <div className="gallery_img">
                                <Link to="/gallery">
                                    <img src={gallery2} alt="" />
                                </Link>
                            </div>
                        </div>

                        <div className="col col-sm-4">
                            <div className="gallery_img">
                                <Link to="/gallery">
                                    <img src={gallery3} alt="" />
                                </Link>
                            </div>
                        </div>

                        <div className="col col-sm-4">
                            <div className="gallery_img">
                                <Link to="/gallery">
                                    <img src={gallery4} alt="" />
                                </Link>
                            </div>
                        </div>

                        <div className="col col-sm-4">
                            <div className="gallery_img">
                                <Link to="/gallery">
                                    <img src={gallery5} alt="" />
                                </Link>
                            </div>
                        </div>

                        <div className="col col-sm-4">
                            <div className="gallery_img">
                                <Link to="/gallery">
                                    <img src={gallery6} alt="" />
                                </Link>
                            </div>
                        </div>

                        <div className="col col-sm-4">
                            <div className="gallery_img">
                                <Link to="/gallery">
                                    <img src={gallery} alt="" />
                                </Link>
                            </div>
                        </div>

                        <div className="col col-sm-4">
                            <div className="gallery_img">
                                <Link to="/gallery">
                                    <img src={gallery7} alt="" />
                                </Link>
                            </div>
                        </div>

                        <div className="col col-sm-4">
                            <div className="gallery_img">
                                <Link to="/gallery">
                                    <img src={gallery8} alt="" />
                                </Link>
                            </div>
                        </div>

                        <div className="col col-sm-4">
                            <div className="gallery_img">
                                <Link to="/gallery">
                                    <img src={gallery9} alt="" />
                                </Link>
                            </div>
                        </div>

                        <div className="col col-sm-4">
                            <div className="gallery_img">
                                <Link to="/gallery">
                                    <img src={gallery10} alt="" />
                                </Link>
                            </div>
                        </div>

                        <div className="col col-sm-4">
                            <div className="gallery_img">
                                <Link to="/gallery">
                                    <img src={gallery11} alt="" />
                                </Link>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export default Gallery