import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './components/header';
import Footer from './components/footer';
import Home from './components/home';
import Contact from './pages/contact';
import Service from './pages/service';
import Gallery from './pages/gallery';
import About from './pages/about';
import Termsandcondition from './pages/termsandcondition';
import Privacypolicy from './pages/privacypolicy';

function App() {
  return (
    <>
      <BrowserRouter>
      <Header />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/gallery' element={<Gallery />} />
          <Route path='/service' element={<Service />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/termsandcondition' element={<Termsandcondition />} />
          <Route path='/privacy' element={<Privacypolicy />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
