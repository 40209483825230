import { useRef } from 'react';
import emailjs from '@emailjs/browser';

import "../styles/contact.css"
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import manimg from "../assets/images/man.png"


const Contact = () => {

  const form = useRef()

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_u75qu0i', 'template_77m8utb', form.current, {
        publicKey: 'oXzzL9lxOoTsLe3uM',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );

      e.target.reset();
  };

  return (
    <>


      <div class="breadcrumb-area" style={{ paddingBottom: "150px", paddingTop: "150px" }}>
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="breadcrumb-text" style={{ textAlign: "center" }}>
                <h1>Contact Us</h1>
                <ul class="breadcrumb-menu">
                  <li><a href="/">Home</a></li>
                  <li><span> <MdKeyboardDoubleArrowRight />
                    Contact Us</span></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div className="contact_section">
        <div className="contact_img" style={{ backgroundImage: "url(https://themeholy.com/wordpress/logistik/wp-content/uploads/2023/04/resquest_bg_1.png)" }}>
          <div className="container">
            <div className="contact_title" style={{ marginTop: "-0.25rem" }}>
              <h2 className="sec-title th-title">Contact Us</h2>
            </div>

            <form ref={form} onSubmit={sendEmail} className="contact_form">
              <input type="text" placeholder='Full Name' name='user_name' required style={{marginTop: "15px", padding: "10px", outline: "none", border: "none", background: "white"}} />
              <input type="email" placeholder='Email' name='user_email' required style={{marginTop: "15px", padding: "10px", outline: "none", border: "none", background: "white"}} />
              <input type="text" placeholder='weight' name='weight' required style={{marginTop: "15px", padding: "10px", outline: "none", border: "none", background: "white"}} />
              <input type="text" placeholder='Distance' name='distance' required style={{marginTop: "15px", padding: "10px", outline: "none", border: "none", background: "white"}} />
              <button type='submit' className='contact_btn' style={{marginTop: "20px"}}>Send Message</button>
            </form>

                <div className="man">
                  <div className="man_img">
                    <img src={manimg} alt="" />
                  </div>
                </div>
      
          </div>
        </div>
      </div>

    </>
  )
}

export default Contact