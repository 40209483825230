import React from 'react';
import "../styles/footer.css"

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="container">
          <div class="footer-middle-area" style={{ marginTop: "50px", paddingTop: "60px" }}>
            <div class="row">
              <div class="col-xl-3 col-lg-3 col-md-6">
                <div class="footer-wrapper" style={{ marginBottom: "30px" }}>
                  <h3 class="footer-title"> Company </h3>
                  <div class="textwidget">
                    <div class="footer-link">
                      <ul>

                        <p>Welcome to MyLoader Transport, your premier transport solution. We specialize in efficient and reliable freight services, ensuring your goods reach their destination safely and on time, every time.</p>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-6">
                <div class="footer-wrapper" style={{ marginBottom: "30px" }}>
                  <h3 class="footer-title">Explore More </h3>
                  <div class="textwidget">
                    <div class="footer-link">
                      <ul>
                        <li><a href="/about">About</a></li>
                        <li><a href="/gallery">Gallery</a></li>
                        <li><a href="/services">Services</a></li>
                        <li><a href="/contact">Contact Us</a></li>
                        <li><a href="/termsandcondition">Terms & Conditions</a></li>
                        <li><a href="/privacy">Privacy policy</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-6">
                <div class="footer-wrapper" style={{ marginBottom: "30px" }}>
                  <h3 class="footer-title">Contact Us </h3>
                  <div class="textwidget">
                    <ul class="contact-link">
                      <li>
                        <div class="contact-address-icon">
                          <i class="fa-solid fa-phone"></i>
                        </div>
                        <div class="contact-address-text">
                          <span>+91 9179970329</span>
                        </div>
                      </li>
                      <li>
                        <div class="contact-address-icon">
                          <i class="far fa-envelope-open"></i>
                        </div>
                        <div class="contact-address-text">
                          <span>myloader93@gmail.com</span>
                        </div>
                      </li>
                      <li>
                        <div class="contact-address-icon">
                          <i class="fa-solid fa-location-dot"></i>
                        </div>
                        <div class="contact-address-text">
                          <span>Indore <br /> Madhya Pardesh, 452016</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-6">
                <div class="footer-wrapper" style={{ marginBottom: "30px" }}>
                  <h3 class="footer-title">About Us </h3>
                  <div class="textwidget">
                    <div class="footer-text">
                      <p>MD of MLTC
                        MR ABHAY JASANI
                        ABHAY SIR IS IN THIS TRANSPORT BUSINESS SINCE LAST 24 YEARS AS A SILENT INVESTOR... NOW MR ABHAY IS READY TO START TRANSPORT SERVICE COMPANY IN MP INDORE, AND MY LOADER TRANCPORT COMPANY IS COMING UP WITH THREE BRANCHES IN MP.</p>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr style={{color: "white"}}></hr>

          <div className="copyright">
            <p className="copyright_txt">2024 copyright LoginWeb Technology</p>
          </div>

        </div>
      </div>


    </>
  )
}

export default Footer;