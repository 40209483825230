import React from 'react';
import "../styles/about.css";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { Link } from 'react-router-dom';
import aboutimg from "../assets/images/about1.jpg"

const About = () => {

  return (
    <>
      <div class="breadcrumb-area" style={{ paddingBottom: "150px", paddingTop: "150px" }}>
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="breadcrumb-text" style={{ textAlign: "center" }}>
                <h1>About Us</h1>
                <ul class="breadcrumb-menu">
                  <li><a href="/">Home</a></li>
                  <li><span> <MdKeyboardDoubleArrowRight />
                    About Us</span></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="about_us">
        <div className="container">
          <div className="row">
            <div className="col col-sm-6">
              <div className="about_us_img">
                <img src={aboutimg} alt="" className='aboutimg' />
              </div>
            </div>

            <div className="col col-sm-6 about_space">
              <div className="about_us_content" style={{ marginBottom: "30px" }}>
                <div className="about_us_title" style={{ marginBottom: "35px" }}>
                  <span className="about_main_title">About Us</span>
                  <h2>Modern &  Trusted MyLoader Company</h2>
                </div>
                <div className="about_us_para">
                  <span>MD of MLTC
                    MR ABHAY JASANI
                    ABHAY SIR IS IN THIS TRANSPORT BUSINESS SINCE LAST 24 YEARS AS A SILENT INVESTOR... NOW MR ABHAY IS READY TO START TRANSPORT SERVICE COMPANY IN MP INDORE, AND MY LOADER TRANCPORT COMPANY IS COMING UP WITH THREE BRANCHES IN MP.</span>
                  <div className="about_button">
                    <Link className="about_btn">Contact Us <i class="fa-regular fa-paper-plane" style={{ marginLeft: "10px" }}></i></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div class="features-02-area" style={{ position: "relative", background: "#f9f9f9", marginTop: "80px" }}>
        <div class="features-bg-img" style={{ backgroundImage: "url(https://i.pinimg.com/564x/12/ff/c3/12ffc32463e0180cafec2dd6de63a5ac.jpg)" }}>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-6 offset-xl-6 col-lg-6 offset-lg-6">
              <div class="features-02-wrapper">
                <div class="about-us-wrapper">
                  <div class="section-title" style={{ position: "relative", marginBottom: "35px" }}>
                    <span class="line">What We Do</span>
                    <h2>Explore Our Main Goals For Business</h2>
                  </div>
                  <div class="features-02-link">
                    <ul>
                      <li>
                        <div class="features-02-icon f-left">
                          <i class="fa-solid fa-anchor"></i>
                        </div>
                        <div class="features-02-info">
                          <h3>Time Maintenance</h3>
                          <p>We excel in precise timekeeping solutions, ensuring optimal scheduling and efficiency. Count on us for meticulous time management tailored to your needs. Let's maximize productivity together!</p>
                        </div>
                      </li>
                      <li>
                        <div class="features-02-icon f-left">
                          <i class="fa-solid fa-car-side"></i>
                        </div>
                        <div class="features-02-info">
                          <h3>Security &amp; Trusted</h3>
                          <p>We prioritize your safety with robust security measures and trustworthy services. Rest assured, your assets and peace of mind are our top priorities. Let's safeguard together. </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="about-button" style={{ marginTop: "30px", marginLeft: "15px" }}>
                    <a class="feature_btn" href="/contact">Enquiry <i class="far fa-paper-plane"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="skills-area" style={{ position: "relative", background: "#f9f9f9"}}>
        <div class="skills-bg-img" style={{backgroundImage: "url(https://shtheme.org/demosd/logio/wp-content/uploads/2020/06/01-4.jpg)"}}>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-6 col-lg-6">
              <div class="skills-wrapper">
                <div class="section-title" style={{marginBottom: "40px", position: "relative"}}>
                  <span class="line">Our Skills</span>
                  <h2>Our Professional Experience &amp; Skills</h2>
                </div>
                <div class="progress-bar-text">
                  <div class="progress-skill">
                    <div class="single-skill" style={{marginBottom: "45px"}}>
                      <div class="bar-title">
                        <h4>Sea Freight</h4>
                      </div>
                      <div class="progress">
                        <div class="progress-bar wow slideInLeft" role="progressbar">
                          <span>80%</span>
                        </div>
                      </div>
                    </div>
                    <div class="single-skill" style={{marginBottom: "45px"}}>
                      <div class="bar-title">
                        <h4>Air Freight</h4>
                      </div>
                      <div class="progress">
                        <div class="progress-bar wow slideInLeft" role="progressbar">
                          <span>70%</span>
                        </div>
                      </div>
                    </div>
                    <div class="single-skill" style={{marginBottom: "45px"}}>
                      <div class="bar-title">
                        <h4>Road Freight</h4>
                      </div>
                      <div class="progress">
                        <div class="progress-bar wow slideInLeft" role="progressbar" >
                          <span>90%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <a class="feature_btn" href="/contact" style={{marginTop: "30px", marginLeft: "60px"}}>appointment <i class="far fa-paper-plane"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default About;