import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import "../styles/header.css"

import logo from "../assets/images/logo.png"


class Header extends Component {

    state = { clicked: false };
    handleClick = () => {
        this.setState({ clicked: !this.state.clicked })
    }

    render() {
        return (
            <>
                <nav>
                    <Link to="/" className="logo"><img src={logo} alt="" /></Link>

                    <div>
                        <ul id='navbar' className={this.state.clicked ? "#navbar active" : "#navbar"}>
                            <li><a href="/">Home</a></li>
                            <li><a href="/about">About Us</a></li>
                            <li><a href="/gallery">Gallery</a></li>
                            <li><a href="/service">Services</a></li>
                            <li><a href="/contact">Enquiry</a></li>
                        </ul>
                    </div>

                    <div className="header_btn">
                        <a href="/contact" className='nav_btn'>Contact Us</a>
                    </div>



                    <div id='mobile' onClick={this.handleClick}>
                        <i id='bar' className={this.state.clicked ? "fas fa-times" : "fas fa-bars"}></i>
                    </div>
                </nav>
            </>
        )
    }
}

export default Header;