import React from 'react';
import "../styles/service.css"
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import road from "../assets/images/road.png"
import { Link } from 'react-router-dom';

import workingimg1 from "../assets/images/working1.png"
import workingimg2 from "../assets/images/working2.png"
import workingimg3 from "../assets/images/working3.png"

const Service = () => {
    return (
        <>
            <div class="breadcrumb-area" style={{ paddingBottom: "150px", paddingTop: "150px" }}>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="breadcrumb-text" style={{ textAlign: "center" }}>
                                <h1>Services</h1>
                                <ul class="breadcrumb-menu">
                                    <li><a href="/">Home</a></li>
                                    <li><span> <MdKeyboardDoubleArrowRight />
                                        Service</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="service_area">
                <div className="container">
                    <div className="row">
                        <div className="col col-sm-12">
                            <div className="service_title" style={{ marginRight: "60px", marginLeft: "60px", marginBottom: "75px", textAlign: "center", position: "relative" }}>
                                <span className="border_left_1"></span>
                                <span>Our Services</span>
                                <span className='border_right_1'></span>
                                <h2>complete road transport solutions under one <br /> roof you name it we do it </h2>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                            <div className="col-sm-3">
                                <div className="service_section">
                                    <div className="service_icon">
                                        <img src={road} alt="" />
                                    </div>
                                    <div className="service_text">
                                        <h3>Tempo Road Freight</h3>
                                        <p> We provide efficient tempo services, ensuring prompt delivery of your goods. Trust us for reliable transportation solutions tailored to your needs.</p>
                                        <div className="service_btn">
                                            <Link to="/contact">Contact Us</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-3">
                                <div className="service_section">
                                    <div className="service_icon">
                                        <img src={road} alt="" />
                                    </div>
                                    <div className="service_text">
                                        <h3>Mini Truck Road Freight</h3>
                                        <p>Providing efficient and reliable transportation with our fleet of mini trucks. Your cargo's journey begins here, ensuring timely and secure deliveries every time.</p>
                                        <div className="service_btn">
                                            <Link to="/contact">Contact Us</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-3">
                                <div className="service_section">
                                    <div className="service_icon">
                                        <img src={road} alt="" />
                                    </div>
                                    <div className="service_text">
                                        <h3>709 Truck Road Freight</h3>
                                        <p>Specializing in 709 truck services, we ensure prompt and secure transportation for your goods. Trust us to deliver excellence every mile of the way.</p>
                                        <div className="service_btn">
                                            <Link to="/contact">Contact Us</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-3">
                                <div className="service_section">
                                    <div className="service_icon">
                                        <img src={road} alt="" />
                                    </div>
                                    <div className="service_text">
                                        <h3>407 Truck Road Freight</h3>
                                        <p>Your go-to for dependable transportation. Count on our 407 trucks and dedicated team for swift and secure cargo delivery. Let's move your goods efficiently!</p>
                                        <div className="service_btn">
                                            <Link to="/contact">Contact Us</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    
                    </div>

                </div>
            </div>


            <div class="working-area" style={{ paddingBottom: "95px", paddingTop: "125px" }}>
        <div class="container">
          <div class="row">
            <div class="col col-sm-12">
              <div class="section-title section-2" style={{ textAlign: "center", position: "relative", marginRight: "60px", marginLeft: "60px", marginBottom: "75px" }}>
                <span class="border-left-1"></span>
                <span>Working Process</span>
                <span class="border-right-1"></span>
                <h2>We Follow Some Steps To <br /> Delivery Product</h2>
              </div>
            </div>

          </div>
          <div class="row">
            <div class="col col-sm-4">
              <div class="working-wrapper" style={{ textAlign: "center", marginBottom: "30px" }}>
                <div class="working-img">
                  <img src={workingimg1} alt="" />
                </div>
                <div class="working-text">
                  <h3>Truck Selection</h3>
                  <p>We specialize in helping you find the perfect truck for your needs, offering a wide range of options tailored to your requirements and budget.</p>
                </div>
              </div>
            </div>
            <div class="col col-sm-4 working1">
              <div class="working-wrapper" style={{ textAlign: "center", marginBottom: "30px" }}>
                <div class="working-img">
                  <img src={workingimg2} alt="" />
                </div>
                <div class="working-text">
                  <h3>Order Shipping</h3>
                  <p>We ensure swift and secure delivery of your orders, utilizing advanced logistics to guarantee timely arrivals. Trust us for efficient shipping services tailored to your needs.</p>
                </div>
              </div>
            </div>
            <div class="col col-sm-4">
              <div class="working-wrapper" style={{ textAlign: "center", marginBottom: "30px" }}>
                <div class="working-img">
                  <img src={workingimg3} alt="" />
                </div>
                <div class="working-text">
                  <h3>Express Delivery</h3>
                  <p>We specialize in lightning-fast delivery services, ensuring your packages reach their destination swiftly and securely. Trust us for unparalleled speed and reliability in shipping.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        </>
    )
}

export default Service;